import  React from 'react';
import { Link } from 'gatsby';
import {Link as GoLink} from 'react-scroll/modules';
import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import ListBrand from '../../../components/list-brand';
import ListLink from '../../../components/list-link';
import Image from '../../../components/Image';


const MorsoPage = () => (
      <Layout page="brand-page leda">
        <Seo title="LEDA"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="flex items-end md:ml-16">
            <Image filename="logo/leda.png" width={180} />
          </h2>
        </section>
        <section className="page-content flex-layout">
          <div className="main-content">
            <div className="content-block">
              <h3 className="sub-title"><span><i className="futura text-xl">LEDA</i>について</span></h3>
              <p>2021年よりアンデルセンストーブに加わったドイツのブランド。工業大国で認められた品質と環境先進国の厳しい排出ガス基準をクリアしたモデルをラインナップしています。鋳物製造から組み立てまで自社で一貫しておこなっておりドイツ国内の鋳物ストーブシェアNo.1の実績です。</p>
              <div className="mt-12">
                <Image className="w-full" filename="leda/about/main.jpg" />
              </div>
            </div>
            <div id="about" className="content-block">
              <div className="md:flex items-center">
                <div className="md:w-1/2 md:pr-4">
                  <h3 className="sub-title">ABOUT</h3>
                  <p className="text-3xl c-yellow leading-relaxed">約150年の鋳物生産の<br />歴史を持つドイツ企業</p>
                  <p className="mt-6">ドイツ北西部ニーダーザクセン州。その中でも西部のオランダ国境に近い都市レーア（Leer）で1873年にLEDA社は鋳物工場を立ち上げました。それ以来150年近くに亘り工業大国ドイツの鋳物産業を牽引しています。創業当時は薪ストーブではなく産業用の鋳物製品を主としておりそこで培われた高い鋳物生産技術を薪ストーブに活かしています。</p>
                  <p className="mt-6">社名のLEDAは近くを流れるLEDA川を由来としておりレーア市内の車止めのポールやモニュメントを手掛けたり自社製品のデザインを地元の大学と連携しておこなうなど地域に密着した企業活動を続けており地域に愛される企業として名をはせています。</p>
                </div>
                <div className="md:w-1/2 md:pl-4">
                  <Image className="full-image" filename="leda/about/logo-image.jpg" />
                  <Link className="text-link en py-0 white mt-12" to="/brand/leda/lineup/">
                    VIEW MORE
                  </Link>
                </div>
              </div>
            </div>
            <div className="content-block">
              <p className="text-lg c-yellow">高い品質を証明する交通インフラや公共機関への納入実績</p>
              <p className="mt-6">LEDA社製品の一番の特徴は妥協なき生産工程と徹底された管理により生み出された“品質”です。その品質の高さは工業大国ドイツでも圧倒的に支持されており納入品に一切の妥協を許さないドイツ最大の鉄道会社であるドイツ鉄道や市や州などの公共機関へ産業用製品を納入しています。少しの不良で大きな事故を起こしかねないため品質を徹底的に求める交通インフラに認められた高品質の鋳物は一見薪ストーブにとっては過剰品質かと思うくらいの手間をかけていますがLEDA社では薪ストーブに対しても一切妥協することなく高品質の鋳物を使用して生産しています。</p>
              <div className="mt-6 md:flex">
                <div className="md:w-1/2">
                  <Image className="w-full" filename="leda/about/performance-01.jpg" />
                </div>
                <div className="md:w-1/2">
                  <Image className="w-full" filename="leda/about/performance-02.jpg" />
                </div>
              </div>
            </div>
            <div className="content-block">
              <p className="text-lg c-yellow">鋳造から組み立てまでの一つの工場で自社一貫生産</p>
              <p className="mt-6">薪ストーブメーカーの多くは直接の鋳物工場は持たず外部に発注した部品を集めて組み立てる生産体制をとっていますがLEDA社は薪ストーブメーカーとしては珍しく自社の鋳物工場を持っており同一敷地内に組立工場も所有しております。鋳物工場から組立工場までを一連で所有していることにより鋳物を生産してから長距離運搬の必要がなくコスト削減ができ運搬時の部品の破損のリスクもありません。何か問題が生じた際は部品のベンダーに調査を依頼するのではなく自社ですべて解決していくことができるのでとても素早い対応ができます。また他社との兼ね合いなどを気にすることなく自社のためだけに自社にとって最高の部品を生産することができます。そして環境への負荷をすくなくするISO9001認証も受けております。こういった様々なことの積み重ねで高品質な薪ストーブが生まれます。</p>
              <div className="mt-6 md:flex">
                <div className="md:w-1/2">
                  <Image className="w-full" filename="leda/about/production-01.jpg" />
                </div>
                <div className="md:w-1/2">
                  <Image className="w-full" filename="leda/about/production-02.jpg" />
                </div>
              </div>
            </div>
            <div id="system" className="content-block left-end md:py-32 py-12">
              <p className="futura text-6xl text-white mb-6">LEDA O2ACTIVE SYSTEM</p>
              <div className="md:flex">
                <div className="md:w-8/12 md:pr-6">
                  <p className="text-3xl c-yellow leading-relaxed">環境に対して負荷を少なく、<br className="pc" />それを実現するための<br className="pc" />伝統技術と最新技術</p>
                  <p className="mt-6">欧米では薪ストーブの煙に含まれる成分に規制があります。<br />
                    ドイツには現在欧州で広く施行されているEN規制よりもさらに厳しい"ドイツ連邦排出規制法BImSchV"があります。<br />
                    この厳しい規制をクリアするために150年の伝統が育んだ職人の技術はもちろん最先端の技術を取り入れる必要があります。</p>
                  <p className="mt-6">最先端の測定機器によりストーブに取り入れた空気が排出されるまでの温度、量、流れ、滞留時間などを細かく調べあげ理想的な空気の流れを生み出すための経路、炉内の大きさや形状を求めてきました。</p>
                  <p className="mt-6">こうして出来上がったLEDA社独自の燃焼構造は</p>
                  <p className="c-gray futura text-2xl mt-6">“LEDA O2ACTIVE SYSTEM”</p>
                  <p className="mt-6">として日本で言われる</p>
                  <p className="c-gray text-2xl mt-6">“クリーンバーンエアー”や”触媒”</p>
                  <p className="mt-6">というものを使用しないシンプルな構造ながら厳しい規制をクリアできるモデルを生み出しました。</p>
                  <Link className="text-link en py-0 mt-12" to="/brand/leda/lineup/">
                    VIEW MORE
                  </Link>
                </div>
                <div className="md:pl-6 md:w-6/12 md:mt-0 mt-12 pr-4 md:pr-0">
                  <div className="md:-mr-20">
                    <Image className="full-image" filename="leda/about/system-01.jpg" />
                  </div>
                  <div className="md:-mr-40 md:pl-28 md:mt-16 mt-6">
                    <Image className="full-image" filename="leda/about/system-02.jpg" />
                  </div>
                </div>
              </div>
              <div className="md:flex md:mt-16 md:pr-20 mt-6 pr-4">
                <div className="md:w-1/2 md:pr-8">
                  <Image className="full-image" filename="leda/about/system-03.jpg" />
                </div>
                <div className="md:w-1/2 md:pl-8 md:mt-0 mt-6">
                  <Image className="full-image" filename="leda/about/system-04.jpg" />
                </div>
              </div>
            </div>
            <div className="content-block">
              <p className="text-lg c-yellow">ドイツ鋳物薪ストーブセールストップシェア、ヨーロッパからアジアへ</p>
              <p className="mt-6">ドイツ国内の薪ストーブ市場は年間15万台以上とも言われており巨大な市場のため海外製の名だたる有名薪ストーブが輸入販売されております。その中でLEDA社は高い品質と技術が評価されドイツ国内の鋳物薪ストーブの中ではトップシェアを誇っております。その高品質な商品はドイツ国内に留まらずヨーロッパ各地への輸出されるようになりLEDAブランドではないOEM商品の生産や他の薪ストーブメーカーへ部品供給をおこなうようになり今もなお勢力を拡大しています。そして2021年アジア市場への進出の足掛かりとして㈱新宮商行を通じて日本で販売することとなりました。</p>
              <div className="mt-6 md:flex">
                <div className="md:w-1/2">
                  <Image className="w-full" filename="leda/about/share-01.jpg" />
                </div>
                <div className="md:w-1/2">
                  <Image className="w-full" filename="leda/about/share-02.jpg" />
                </div>
              </div>
            </div>
            <div id="pdf" className="content-block">
              <Link className="btn full white" to="/brand/leda/lineup/">
                LEDA 商品一覧
              </Link>
              <a className="btn pdf full mt-5" href="/update/pdf/andersen.pdf" target="_blank">
                PDFカタログ ダウンロード
              </a>
            </div>
          </div>
          <div className="side-list">
        <ul className="sticky">
              <li>
                <GoLink to="about" spy smooth duration={800}>
                  ABOUT
                </GoLink>
              </li>
              <li>
                <GoLink to="system" spy smooth duration={800}>
                  LEDA O2ACTIVE SYSTEM
                </GoLink>
              </li>
              <li>
                <GoLink className="c-attention" to="pdf" spy smooth duration={800}>
                  PDFカタログ
                </GoLink>
              </li>
            </ul>
          </div>
        </section>
        <section className="bread">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>
              <Link to="/brand/">ブランド</Link>
            </li>
            <li>
              <Link to="/brand/leda/">LEDA</Link>
            </li>
            <li>LEDAについて</li>
          </ul>
        </section>
      </Layout>
  );

export default MorsoPage;
